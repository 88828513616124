import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ArrowRightIcon, Search, MapPin, Compass } from "lucide-react";
import DuneImage from "../../../assets/Dubai-Loading.jpg";
import SlImage from "../../../assets/travel.jpg";
import SLText from "../../../assets/text_SL.svg";
import UAEText from "../../../assets/uae.png";

const SearchAnimation = () => {
  const [currentIcon, setCurrentIcon] = useState(0);
  const icons = [
    <Search key="search" className="w-8 h-8 md:w-12 md:h-12" />,
    <MapPin key="map" className="w-8 h-8 md:w-12 md:h-12" />,
    <Compass key="compass" className="w-8 h-8 md:w-12 md:h-12" />,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIcon((prev) => (prev + 1) % icons.length);
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      className="text-white mt-4 md:mt-8"
      animate={{
        scale: [1, 1.1, 1],
        opacity: [0.7, 1, 0.7],
      }}
      transition={{
        duration: 0.3,
        repeat: Infinity,
        ease: "easeInOut",
      }}
    >
      {icons[currentIcon]}
    </motion.div>
  );
};

const TypewriterText = ({ text, onComplete }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let isMounted = true;
    const typeText = () => {
      for (let i = 0; i <= text.length; i++) {
        setTimeout(() => {
          if (isMounted) {
            setDisplayedText(text.slice(0, i));
            if (i === text.length) {
              setTimeout(() => onComplete(), 1000);
            }
          }
        }, i * 100);
      }
    };

    typeText();

    return () => {
      isMounted = false;
    };
  }, [text, onComplete]);

  return (
    <div className="flex flex-col items-center">
      <motion.h1
        className="text-3xl md:text-5xl lg:text-7xl font-bold text-white text-center z-50 px-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{
          opacity: 0,
          y: -100,
          transition: { duration: 1, ease: "easeInOut" },
        }}
      >
        {displayedText}
        <motion.span
          className="inline-block w-1 md:w-2 h-6 md:h-10 bg-white"
          animate={{
            opacity: [0, 1, 0],
            transition: {
              duration: 0.5,
              repeat: Infinity,
            },
          }}
        />
      </motion.h1>
      <SearchAnimation />
    </div>
  );
};

const DestinationCard = ({ 
  image, 
  title, 
  description, 
  destination, 
  customDescriptionOffset = { y: 0 }, 
  customExploreOffset = { y: 0 } 
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      className="relative w-full md:w-1/2 h-screen overflow-hidden group cursor-pointer"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      whileHover={{
        scale: 1.02,
        transition: { duration: 0.3 },
      }}
      whileTap={{ scale: 0.98 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => (window.location.href = destination)}
    >
      <motion.div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${image})`,
          overflow: "hidden",
        }}
        animate={{
          scale: isHovered ? 1.1 : 1,
          transition: { duration: 0.3 },
        }}
      />

      <div className="absolute inset-0 bg-black bg-opacity-30 transition-all duration-300 group-hover:bg-opacity-20" />

      <div className="absolute inset-0 flex flex-col justify-center items-center text-white p-4 md:p-8">
        <motion.div
          className="text-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.6, // Slightly shorter duration
              delay: 0.1, // Reduced delay
              ease: "easeOut",
            },
          }}
        >
          {typeof title === "string" ? (
            <motion.h1
              className="text-4xl md:text-6xl font-extrabold mb-4 tracking-wide"
              whileHover={{ scale: 1.05 }}
            >
              {title}
            </motion.h1>
          ) : (
            title
          )}
        </motion.div>

        <motion.p
          className="text-base md:text-xl text-center max-w-lg mx-4"
          initial={{ opacity: 0, y: 30 }}
          animate={{
            opacity: 1,
            y: customDescriptionOffset.y,
            transition: {
              duration: 0.6, // Slightly shorter duration
              delay: 0.2, // Reduced delay
              ease: "easeOut",
            },
          }}
        >
          {description}
        </motion.p>

        <motion.div
          className="mt-6 md:mt-8 flex items-center justify-center space-x-3 bg-white bg-opacity-20 backdrop-blur-sm px-4 md:px-6 py-2 md:py-3 rounded-full hover:bg-opacity-30"
          initial={{ opacity: 0, y: 30 }}
          animate={{
            opacity: 1,
            y: customExploreOffset.y,
            transition: {
              duration: 0.6, // Slightly shorter duration
              delay: 0.3, // Reduced delay
              ease: "easeOut",
            },
          }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <span className="font-semibold text-base md:text-lg">Explore</span>
          <motion.div
            animate={{
              x: isHovered ? 5 : 0,
            }}
            transition={{
              type: "spring",
              stiffness: 200,
              damping: 10,
            }}
          >
            <ArrowRightIcon className="w-4 h-4 md:w-6 md:h-6" />
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  );
};

const LoadingPage = () => {
  const [isIntroComplete, setIsIntroComplete] = useState(false);

  const handleIntroComplete = () => {
    setTimeout(() => {
      setIsIntroComplete(true);
    }, 500);
  };

  return (
    <div className="relative h-screen w-screen overflow-hidden">
      <motion.div
        className="absolute inset-0 flex flex-col md:flex-row"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="w-full md:w-1/2 h-1/2 md:h-full">
          <div
            className="h-full w-full bg-cover bg-center"
            style={{ backgroundImage: `url(${SlImage})` }}
          />
        </div>
        <div className="w-full md:w-1/2 h-1/2 md:h-full">
          <div
            className="h-full w-full bg-cover bg-center"
            style={{ backgroundImage: `url(${DuneImage})` }}
          />
        </div>
        <div className="absolute inset-0 bg-black bg-opacity-40" />
      </motion.div>

      <AnimatePresence mode="wait">
        {!isIntroComplete ? (
          <motion.div
            key="intro"
            className="absolute inset-0 flex items-center justify-center"
            exit={{
              opacity: 0,
              transition: { duration: 0.5 },
            }}
          >
            <TypewriterText
              text="What is your Dream Destination...?"
              onComplete={handleIntroComplete}
            />
          </motion.div>
        ) : (
          <motion.div
            key="destinations"
            className="absolute inset-0 flex flex-col md:flex-row"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <DestinationCard
              image={SlImage}
              title={
                <img
                  src={SLText}
                  alt="Sri Lanka"
                  className="h-[150px] relative"
                  style={{ top: "30px", maxHeight: "200px" }}
                />
              }
              description="Discover lush landscapes, ancient temples, and vibrant culture in this tropical paradise."
              destination="https://srilanka.rajaratatours.com/"
            />

            <DestinationCard
              image={DuneImage}
              title={
                <img
                  src={UAEText}
                  alt="UAE"
                  className="h-[300px]  relative"
                  style={{ top: "80px", maxHeight: "350px" }}
                />
              }
              description="Experience ultramodern architecture, luxury shopping, and desert adventures."
              destination="https://uae.rajaratatours.com/"
              customDescriptionOffset={{ y: -50 }}
              customExploreOffset={{ y: -50 }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LoadingPage;