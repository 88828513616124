// App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoadingPage from "./components/FrontEnd/LoadinPage/LoadingPage";
// import AdminLogin from "./components/Admin/AdminLogin";
// import AdminPanel from "./components/Admin/AdminPanel";
// import LocationView from './components/FrontEnd/LocationsPage/LocationView';
// import TripsView from './components/FrontEnd/TourPage/TripsView';
// import TripInfo from './components/FrontEnd/TourPage/TripInfo';
// import AboutUs from "./components/FrontEnd/AboutUS/AboutUs";
// import LocationForm from "./components/FrontEnd/LocationsPage/LocationForm";
// import ContactForm from "./components/FrontEnd/ContactUs/ContactForm";
// import ComboTourView from "./components/FrontEnd/ComboTour/ComboTourView";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LoadingPage />} />
          {/* <Route path="/locations" element={<LocationView />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/admin-panel" element={<AdminPanel />} />
          <Route path="/trips-view" element={<TripsView />} />
          <Route path="/contact-us" element={<ContactForm />} />
          <Route path="/combo-tour" element={<ComboTourView />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/location-form" element={<LocationForm />} />
        <Route path="/tour/:tourName" element={<TripInfo />} /> */}
        
        </Routes>
      </div>
    </Router>
  );
}

export default App;
